<template>
	<div class="app-body">
		<div class="a-flex-rsbc a-ptb-6">
			<span class="a-fs-16 a-fw-b">站点管理</span>
			<div class="a-flex-rcc">
				<el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button>
				<el-button icon="el-icon-plus" type="primary" class="s-btn-add a-ml-24" @click="handlerAddStation" v-if="$_has(7)">站点</el-button>
			</div>

		</div>


		<el-card class="el-main">
			<le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="false" id="leSearch">
				<!-- <le-input-icon-search label="站点名称" v-model="pageParam.params.searchKey"  placeholder="请输入站点名称" /> -->
				<le-input-icon-search label="站点ID" v-model="pageParam.params.stationId" placeholder="请输入站点ID" />
                <le-select-remote-search
                    label="站点"
                    reserveKeyword 
                    selectAll 
                    multiple 
                    collapse
                    v-model="pageParam.params.stationIds" 
                    :options="optionsStation" 
                    placeholder="请选择站点（可输入搜索）" />
				<le-select-local-search label="收益类型" v-model="pageParam.params.isSearchAgency"
					:options="stationTypeOptions" />
                <le-select-local-search label="站点类型" v-model="pageParam.params.stationType"
					:options="stationTypeDic" />
				<le-select-local-search label="站点状态" v-model="pageParam.params.status"
					:options="stationStatusOptions" />
				<le-input-district-choose label="所在地区" :province.sync="pageParam.params.provinceCode"
					:city.sync="pageParam.params.cityCode"
					:area.sync="pageParam.params.districtCode"></le-input-district-choose>
				<le-company-under-select label="所属商户" v-model="pageParam.params.searchCompanyId"></le-company-under-select>
                <le-select-local-search label="关联项目" v-model="pageParam.params.joinIncome" :clearable='false'
					:options="yesOrNoDic" />
                <le-select-remote-search label="站点标签" v-model="pageParam.params.tagId" :options="optionsStationlabel" placeholder="请选择标签(可搜索)" />
			</le-search-form>

			<le-pagview ref="stationlist" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.stationlistTable">
				<el-table ref="stationlistTable" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }">
					<el-table-column prop="id" label="站点ID" min-width="100" >
					</el-table-column>
                    <el-table-column prop="name" label="站点名称" min-width="150" >
					</el-table-column>
					<el-table-column prop="address" label="地址" min-width="150" >
					</el-table-column>
					<el-table-column prop="statusText" label="站点状态" min-width="100">
					</el-table-column>
                    <el-table-column prop="statusText" label="站点类型" min-width="100">
                        <template slot-scope="{ row }">
                            <span>{{ row.stationType | initDic(stationTypeDic) }}</span>
                        </template>
					</el-table-column>
					<el-table-column prop="detailedStateText" label="详细状态" min-width="100">
					</el-table-column>
					<el-table-column prop="applyDate" label="建站日期" min-width="120">
					</el-table-column>
					<el-table-column prop="onlineDeviceNum" label="在线设备数" min-width="100">
					</el-table-column>
					<el-table-column prop="totalDeviceNum" label="设备总数" min-width="100">
					</el-table-column>
					<!-- <el-table-column prop="usingDeviceNum" label="使用中" min-width="100">
					</el-table-column> -->
					<el-table-column prop="usingDeviceSlotNum" label="使用中(插槽)" min-width="100">
					</el-table-column>
                    <el-table-column prop="patrolNumMonth" label="本月巡检" min-width="100">
                        <template slot-scope="{ row }">
							<span :class="!row.patrolNumMonth ? 'a-c-error' : ''">{{ row.patrolNumMonth || '0' }}次</span>
						</template>
					</el-table-column>
                    <el-table-column prop="usingDeviceSlotNum" label="距离上次巡检" min-width="100">
                        <template slot-scope="{ row }">
                            <span v-if="!row.lastPatrolTime">-</span>
                            <span v-else-if="!$getDay.enumerateDaysBetweenDates(row.lastPatrolTime,$getDay.getToday()).length">1天</span>
                            <span v-else>{{ $getDay.enumerateDaysBetweenDates(row.lastPatrolTime,$getDay.getToday()).length - 1 }}天</span>
						</template>
					</el-table-column>
					<!-- <el-table-column label="今日营业额" min-width="100">
						<template slot-scope="{ row }">
							<span>{{  util.numFormat(row.todayOrderAmount)  }}元</span>
						</template>
					</el-table-column> -->
					<!-- <el-table-column prop="todayOrderNum" label="今日订单量" min-width="100">
					</el-table-column>
					<el-table-column label="昨日营业额" min-width="100">
						<template slot-scope="{ row }">
							<span>{{  util.numFormat(row.yesterdayOrderAmount)  }}元</span>
						</template>
					</el-table-column>
					<el-table-column prop="yesterdayOrderNum" label="昨日订单量" min-width="100">
					</el-table-column> -->


					<el-table-column label="操作" width="80" fixed="right">
						<template slot-scope="{ row }">
							<div class="a-flex-rcc">
								<el-tooltip class="item" effect="dark" content="站点详细" placement="top">
									<div @click="handlerGoDetail(row)">
										<img src="../../assets/icon/option-detail.png" class="a-wh-16" />
									</div>
								</el-tooltip>
                                <el-tooltip class="item" effect="dark" content="编辑站点" placement="top">
									<div @click="handlerEditStation(row)" v-if="(userInfo.dataPermission == 99 || userInfo.dataPermission == 88) && $_has(7)">
										<img src="../../assets/icon/option-edit.png" class="a-wh-16 a-ml-16" />
									</div>
								</el-tooltip>
							</div>

						</template>
					</el-table-column>
				</el-table>
			</le-pagview>
		</el-card>
	</div>
</template>

<script>
import util from "../../utils/util.js";
import { mapState } from 'vuex';
export default {
	mounted() { },
	data() {
		return {
			util: util,

			stationTypeOptions: [{
				value: "0",
				label: "自营",
			},
			{
				value: "1",
				label: "参与收益",
			},
			{
				value: "2",
				label: "全部",
			},
			],
			stationStatusOptions: [{
				value: "2",
				label: "运营中",
			},
			{
				value: "0",
				label: "申报中",
			},
			{
				value: "1",
				label: "建设中",
			},
			{
				value: "3",
				label: "已撤站",
			},
			],
			activeTab: "2",

			tableData: [],
			pageParam: {
				url: this.$Config.apiUrl.getStationInfoList,
				method: "post",
				params: {
					searchKey: "",
                    stationIds: [],//站点ID
                    stationId: '',
					status: "",
					isSearchAgency: '2',
					searchCompanyId:'',
					districtCode:'',
					cityCode:'',
					provinceCode:'',
                    joinIncome: '0',
                    tagId: '',
                    stationType: ''
				},
				freshCtrl: 1,
			},
            optionsStationlabel: {
                url: this.$Config.apiUrl.getStationTagList, //getIncomeStatInfoList
                method: "post",
                params: {
                    searchKey: "",
                },
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "name",
            },
            optionsStation: {
                url: this.$Config.apiUrl.getStationList,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "stationId",
                searchKey: "name",  //incomeDistriId
                pageSize: 200
            },//站点数据
            yesOrNoDic: [],
            stationTypeDic: [],
		};
	},
	mounted() { 
        this.$getDic('yesOrNo','select').then(res=>{ this.yesOrNoDic = res; })
        this.$getDic('stationType','select').then(res=>{ this.stationTypeDic = res; })
    },
	activated () {
        if(this.$route.query.labelId){
            this.pageParam.params.tagId = Number(this.$route.query.labelId)
        }
		this.pageParam.freshCtrl++;
	},
    computed:{
        ...mapState({
            userInfo: state => state.user.user,
        })
    },
	methods: {

		//获取车辆列表
		setTableData(data) {
			this.tableData = data;
            let sta = this.tableData.map(item=>{
                return item.id
            })
            console.log(sta.toString());
		},

		handlerRest() {
			this.pageParam.params = {
				searchKey: "",
                stationIds: [],//站点ID
                stationId: '',
                status: "",
                isSearchAgency: '2',
                searchCompanyId:'',
                districtCode:'',
                cityCode:'',
                provinceCode:'',
                joinIncome: '0',
                tagId: ''
			}
            this.handlerSearch()
		},

		handlerSearch() {
			this.$refs['stationlist'].pageNum = 1
			this.pageParam.freshCtrl++
		},

		handlerAddStation() {
			this.$router.push({
				path: '/station/station-add'
			})
		},

		handlerGoDetail(item) {
			if (item.status == 0) {
				this.$router.push({
					path: '/station/station-apply',
					query: {
						stationId: item.id
					}
				})
			}else{
				this.$router.push({
					path: '/station/station-detail',
					query: {
						stationId: item.id
					}
				})
			}
		},
        handlerEditStation (item) {
            this.$router.push({
                path: '/station/station-add',
                query:{
                    stationId: item.id
                }
            })
        },
        exportfile () {
            this.$exfile({
                code: 26,
                fileName: '站点列表',
                params: {
                    ...this.pageParam.params,
                }
            })
        },
	}
}
</script>

<style scoped lang="scss">
	/deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
    /deep/ .s-search-label {
        width: 92px !important;
    }
</style>
